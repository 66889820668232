import React from "react"
import { Helmet as ReactHelmet } from "react-helmet"

const Helmet = () => {
  return (
    <ReactHelmet>
      <link
        href="https://fonts.googleapis.com/css?family=Crimson+Text&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Satisfy&display=swap"
        rel="stylesheet"
      />
    </ReactHelmet>
  )
}

export default Helmet
