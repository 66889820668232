import React from "react"
import * as cx from "classnames"
import styles from "./highlightText.module.css"

const HighlightText = ({ children, showOnMobile }) => {
  return (
    <span className={cx(styles.link, { [styles.mobile]: showOnMobile })}>
      {children}
    </span>
  )
}

export default HighlightText
