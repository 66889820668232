import React, { useState } from "react"
import { FiMenu, FiX } from "react-icons/fi"
import { Link } from "gatsby"
import Collapse from "react-collapse"
import Headroom from "react-headroom"
import withSize from "react-sizeme"

import HighlightText from "../highlightText"
import styles from "./header.module.css"

const Header = ({ size, siteTitle, navigation }) => {
  const smallScreen = size.width <= 1000
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <Headroom
        style={{ zIndex: 10, backgroundColor: "white" }}
        onUnpin={() => setMenuOpen(false)}
      >
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            <Link className={styles.link} to="/">
              <HighlightText>{siteTitle}</HighlightText>
            </Link>
          </h1>
          <div className={styles.navContainer}>
            {smallScreen && !menuOpen && (
              <FiMenu
                className={styles.menuButton}
                onClick={() => setMenuOpen(true)}
              />
            )}
            {smallScreen && menuOpen && (
              <FiX
                className={styles.menuButton}
                onClick={() => setMenuOpen(false)}
              />
            )}
            <div className={styles.navLinks}>
              <Collapse isOpened={!smallScreen || menuOpen}>
                {navigation.map(nav => (
                  <h3 key={nav.title} className={styles.navItem}>
                    <Link className={styles.link} to={nav.to}>
                      <HighlightText>{nav.title}</HighlightText>
                    </Link>
                  </h3>
                ))}
              </Collapse>
            </div>
          </div>
        </div>
      </Headroom>
    </>
  )
}

export default withSize()(Header)
